<template>
  <div class="user-card">

    <div class="user-card__img">
      <div class="user-card__img-i" v-if="user.userAvatar.id">
        <img
             :src="`data:${user.userAvatar.mimeType};base64,${user.userAvatar.base64}`" alt="img">
        <!--<img v-else src="/img/trash-group/user-photo.jpg" alt="img">-->
      </div>
    </div>
    <div class="user-card__content">
      <div class="user-card__head">
        <div class="user-card__head-info">
          <div class="user-card__head-name" v-if="Object.keys(user.user).length > 0">
            <ValueHelper
                :value="getUserContactDataFromUser(user.user)"
                :deep="'user_full_name'"
            />
          </div>
          <div class="user-card__head-row">
            <div class="amount-row light"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_Balance'])"></div>
              {{ $t('userModule_Balance.localization_value.value') }}:
              <span class="important font-weight-bold m-0"
                    v-bind:class="{'color-red': parseFloat(user.user.balance) < 0}"
              >
                $ {{ user.user.balance }}
              </span>
            </div>
          </div>
          <div class="user-card__head-row" v-if="_.has(user.user, 'id') && user.user.user_personal_contact.birthday">
            <div class="amount-row light"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['profile_birthday'])"></div>
              {{ $t('profile_birthday.localization_value.value') }}:
              <span class="m-0">{{ user.user.user_personal_contact.birthday }}</span>
            </div>
          </div>

          <div class="user-card__head-row">
            <div class="site-link brown text-decoration-underline mt-2">
              <div v-if="isAdmin && UEAdmin.data.historyOfChanged"
                   class="mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
                <div class="site-link text-decoration-underline btn-style brown"
                     @click="historyOfChangesFunc('userSettings', UEAdmin.data.historyOfChanged)">
                  {{$t('common_CheckHistory.localization_value.value')}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-card__head-mark">
          <BlockedBlock
              v-if="user.user.locked"
              :value="'BLOCKED'"
          />
        </div>
      </div>

      <div class="user-card__body">
        <div class="block-table">
          <div class="block-table__row mb-0">
            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_Phone', 'common_edit'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('userModule_Phone.localization_value.value')"
                  :rightBtn="!showType"
                  :rightBtnType="'edit'"
                  :rightBtnText="$t('common_edit.localization_value.value')"
                  @rightBtnClick="changePhonesPopup(true)"
              />
            </div>

            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['userModule_MainPhone'])"></div>
                {{ $t('userModule_MainPhone.localization_value.value') }}
              </div>

              <div class="block-table__content">
                {{ user.userPhone }}

                <div class="block-table__ico ml-2">
                  <TelegramBlackIcon/>
                </div>
              </div>
            </div>

            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_CustomerInformation', 'common_edit'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('userModule_CustomerInformation.localization_value.value')"
                  :rightBtn="!showType"
                  :rightBtnType="'edit'"
                  :rightBtnText="$t('common_edit.localization_value.value')"
                  @rightBtnClick="changePersonalEditingPopup(true)"
              />
            </div>


            <div class="block-table__col mb-3">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['userModule_Email'])"></div>
                {{ $t('userModule_Email.localization_value.value') }}
              </div>

              <div class="block-table__content">
                {{ user.userEmail }}
              </div>
            </div>

            <template v-if="!showType">
              <div class="block-table__col mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                <TitleHorizontal
                    class="black mb-0"
                    :value="$t('common_address.localization_value.value')"
                    :rightBtn="!showType"
                    :rightBtnType="'edit'"
                    :rightBtnText="$t('common_edit.localization_value.value')"
                    @click.native="changeChangeAddressPopup(true)"
                />
              </div>

              <div class="block-table__col mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                  {{ $t('common_address.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{ !user.user.use_alternative_address ? user.user.user_personal_contact.address : user.user.user_alternative_contact.address }}
                </div>
              </div>

              <div class="block-table__col mb-3" v-if="user.user.user_personal_contact.cyrillic_address">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_cityCyrillic'])"></div>
                  {{ $t('common_cityCyrillic.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{ user.user.user_personal_contact.cyrillic_address }}
                </div>
              </div>
              <!--<div class="block-table__col mb-3">-->
              <!--<div class="block-table__label">-->
              <!--Address 2-->
              <!--</div>-->

              <!--<div class="block-table__content">-->
              <!-- - -->
              <!--</div>-->
              <!--</div>-->

              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
                  {{ $t('common_city.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{ !user.user.use_alternative_address ? user.user.user_personal_contact.city : user.user.user_alternative_contact.city }}
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_regions'])"></div>
                  {{ $t('common_regions.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      v-if="!user.user.use_alternative_address"
                      :value="user.user"
                      :deep="'user_personal_contact.region.name'"
                      :replaceValue="'—'"
                  />
                  <ValueHelper
                      v-else
                      :value="user.user"
                      :deep="'user_alternative_contact.region.name'"
                      :replaceValue="'—'"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                  {{ $t('common_country.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      v-if="!user.user.use_alternative_address"
                      :value="user.user.user_personal_contact"
                      :deep="'country.name'"
                  />
                  <ValueHelper
                      v-else
                      :value="user.user.user_alternative_contact"
                      :deep="'country.name'"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_postNumber'])"></div>
                  {{ $t('common_postNumber.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{ !user.user.use_alternative_address ? user.user.user_personal_contact.zip : user.user.user_alternative_contact.zip }}
                </div>
              </div>

              <div class="block-table__col block-table__col--50 mb-3"
                   v-if="user.user.user_personal_contact.country.id === RUSSIA_COUNTRY_ID"
              >
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['userModule_ITNCode'])"></div>
                  {{ $t('userModule_ITNCode.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{ user.userITN }}
                </div>
              </div>
            </template>

            <template v-if="showType">
              <div class="block-table__col mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                <TitleHorizontal
                    class="black mb-0"
                    :value="$t('common_address.localization_value.value')"
                    :rightBtn="!showType"
                    :rightBtnType="'edit'"
                    :rightBtnText="$t('common_edit.localization_value.value')"
                    @click.native="changeChangeAddressPopup(true)"
                />
              </div>

              <div class="block-table__col mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                  {{ $t('common_address.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{user.user.user_personal_contact.address}}
                </div>
              </div>

              <div class="block-table__col mb-3" v-if="user.user.user_personal_contact.cyrillic_address">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_cityCyrillic'])"></div>
                  {{ $t('common_cityCyrillic.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{ user.user.user_personal_contact.cyrillic_address }}
                </div>
              </div>

              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
                  {{ $t('common_city.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{ user.user.user_personal_contact.city }}
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_regions'])"></div>
                  {{ $t('common_regions.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="user.user"
                      :deep="'user_personal_contact.region.name'"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                  {{ $t('common_country.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="user.user.user_personal_contact"
                      :deep="'country.name'"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_postNumber'])"></div>
                  {{ $t('common_postNumber.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  {{ user.user.user_personal_contact.zip }}
                </div>
              </div>


              <template v-if="user.user.use_alternative_address">
                <div class="block-table__col mb-3"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_alternative_address'])"></div>
                  <TitleHorizontal
                      class="black mb-0"
                      :value="$t('common_alternative_address.localization_value.value')"
                      :rightBtn="!showType"
                      :rightBtnType="'edit'"
                      :rightBtnText="$t('common_edit.localization_value.value')"
                      @click.native="changeChangeAddressPopup(true)"
                  />
                </div>

                <div class="block-table__col mb-3">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                    {{ $t('common_address.localization_value.value') }}
                  </div>

                  <div class="block-table__content">
                    {{user.user.user_alternative_contact.address}}
                  </div>
                </div>

                <div class="block-table__col mb-3" v-if="user.user.user_alternative_contact.cyrillic_address">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_cityCyrillic'])"></div>
                    {{ $t('common_cityCyrillic.localization_value.value') }}
                  </div>

                  <div class="block-table__content">
                    {{ user.user.user_alternative_contact.cyrillic_address }}
                  </div>
                </div>

                <div class="block-table__col block-table__col--50 mb-3">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
                    {{ $t('common_city.localization_value.value') }}
                  </div>

                  <div class="block-table__content">
                    {{ user.user.user_alternative_contact.city }}
                  </div>
                </div>
                <div class="block-table__col block-table__col--50 mb-3">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_regions'])"></div>
                    {{ $t('common_regions.localization_value.value') }}
                  </div>

                  <div class="block-table__content">
                    <ValueHelper
                        :value="user.user"
                        :deep="'user_alternative_contact.region.name'"
                    />
                  </div>
                </div>
                <div class="block-table__col block-table__col--50 mb-3">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                    {{ $t('common_country.localization_value.value') }}
                  </div>

                  <div class="block-table__content">
                    <ValueHelper
                        :value="user.user.user_alternative_contact"
                        :deep="'country.name'"
                    />
                  </div>
                </div>
                <div class="block-table__col block-table__col--50 mb-3">
                  <div class="block-table__label"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['common_postNumber'])"></div>
                    {{ $t('common_postNumber.localization_value.value') }}
                  </div>

                  <div class="block-table__content">
                    {{ user.user.user_alternative_contact.zip }}
                  </div>
                </div>
              </template>
            </template>



            <div class="block-table__col mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_Security','common_edit'])"></div>
              <TitleHorizontal
                  class="black mb-0"
                  :value="$t('userModule_Security.localization_value.value')"
                  :rightBtn="!showType"
                  :rightBtnType="'edit'"
                  :rightBtnText="$t('common_edit.localization_value.value')"
                  @rightBtnClick="changeChangePasswordPopup(true)"
              />
            </div>
            <template v-if="!showType">
              <div class="block-table__col mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['login_password'])"></div>
                  {{ $t('login_password.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  *******
                </div>
              </div>
            </template>
            <div class="block-table__col mb-3">
              <div class="block-table__content">
                <div class="site-link"
                     v-if="user.user.auth_type === TWO_FACTOR_AUTH_TYPE.GOOGLE"
                     @click="showUrgentCodesPopup">
                  {{ $t('profile_viewUrgent.localization_value.value') }}
                </div>
              </div>
            </div>

            <div class="block-table__col mt-2" v-if="user.user.company_owner">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_subUserBy'])"></div>
                {{ $t('common_subUserBy.localization_value.value') }}
              </div>

              <div class="block-table__content">
                <router-link class="table-link btn-style" :to="$store.getters.GET_PATHS.mainSettingsUsers + '/show/' + user.user.company_owner.id">
                  <ValueHelper
                      :value="user.user"
                      :deep="'company_owner.email'"
                  />
                </router-link>
              </div>
            </div>

            <template v-if="UEAdmin.data.userAccess.data.changeAddressLabelsYourself">
              <div class="block-table__col mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_selfLabelAddress'])"></div>
                <TitleHorizontal
                    class="black mb-0"
                    :value="$t('common_selfLabelAddress.localization_value.value')"
                    :rightBtn="!showType"
                    :rightBtnType="'edit'"
                    :rightBtnText="$t('common_edit.localization_value.value')"
                    @click.native="selfLabelContactPopup(true)"
                />
              </div>

              <div class="block-table__col mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                  {{ $t('common_address.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="user.user"
                      :deep="'self_label_contact.address'"
                  />
                </div>
              </div>

              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
                  {{ $t('common_city.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="user.user"
                      :deep="'self_label_contact.city'"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_regions'])"></div>
                  {{ $t('common_regions.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="user.user"
                      :deep="'self_label_contact.region.name'"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                  {{ $t('common_country.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="user.user"
                      :deep="'self_label_contact.country.name'"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_postNumber'])"></div>
                  {{ $t('common_postNumber.localization_value.value') }}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="user.user"
                      :deep="'self_label_contact.zip'"
                  />
                </div>
              </div>
            </template>

          </div>

        </div>


      </div>


      <UrgentCodesPopup
          v-if="isModalUrgentCodesPopup"
          :userId="user.user.id"
          @close="closeUrgentCodesPopup"
      />

      <ChangePassword
          v-if="isModalChangePasswordPopup"
          :forUser="true"
          :user="user"
          @close="changeChangePasswordPopup(false)"
      />

      <PersonalEditing
          v-if="isModalPersonalEditingPopup"
          :user="user.user"
          @reload="$emit('reload')"
          @close="changePersonalEditingPopup(false)"
      />

      <ChangePhonesPopup
          v-if="isModalPhonesPopup"
          :user="user"
          @reload="$emit('reload')"
          @close="changePhonesPopup(false)"
      />

      <ChangeAddressPopup
          v-if="isModalChangeAddressPopup || isModalSelfLabelContactPopup"
          :user="isModalSelfLabelContactPopup ? UEAdmin.data.userSelfLabelContact : user"
          :selfLabelContact="isModalSelfLabelContactPopup"
          :userId="user.userId"
          @reload="$emit('reload')"
          @saveUser="$emit('saveUser')"
          @close="changeChangeAddressPopup(false)"
      />

    </div>
  </div>
</template>

<script>
import BlockedBlock from "../../../../../coreComponents/BlockedBlock/BlockedBlock";
import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import TelegramBlackIcon from "../../../../../../../public/img/social-group/telegram-black-icon.svg?inline"
import ChangePhonesPopup from "../../../../../coreComponents/Popups/ChangePhonesPopup/ChangePhonesPopup";
import ChangeAddressPopup from "../../../popups/ChangeAddressPopup/ChangeAddressPopup";
import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";
import PersonalEditing from "../../../../../coreComponents/Popups/PersonalEditing/PersonalEditing";
import ChangePassword from "../../../../../coreComponents/Popups/ChangePassword/ChangePassword";
import {RUSSIA_COUNTRY_ID} from "../../../../../../staticData/staticVariables";
import UrgentCodesPopup from "@/components/modules/ProfileModule/popups/UrgentCodesPopup/UrgentCodesPopup";

export default {
  name: "UserCard",
  components: {
    UrgentCodesPopup,
    PersonalEditing,
    ValueHelper,
    BlockedBlock,
    TitleHorizontal,
    ChangePassword,
    ChangePhonesPopup,
    TelegramBlackIcon,
    ChangeAddressPopup,
  },

  props: {
    UEAdmin: Object,
    user: Object,
    showType: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isModalChangePasswordPopup: false,
      isModalPhonesPopup: false,
      isModalChangeAddressPopup: false,
      isModalHistoryOfChanges: false,
      isModalPersonalEditingPopup: false,
      isModalUrgentCodesPopup: false,
      isModalSelfLabelContactPopup: false,

      RUSSIA_COUNTRY_ID: RUSSIA_COUNTRY_ID,

      TWO_FACTOR_AUTH_TYPE: {
        EMAIL: 'email',
        GOOGLE: 'google',
      },
    }
  },

  mounted() {
    console.log(this.UEAdmin);
  },

  methods: {

    showUrgentCodesPopup() {
      this.isModalUrgentCodesPopup = true
    },

    closeUrgentCodesPopup() {
      this.isModalUrgentCodesPopup = false
    },


    changePersonalEditingPopup(val) {
      this.isModalPersonalEditingPopup = val
    },

    changeChangePasswordPopup(val) {
      this.isModalChangePasswordPopup = val
    },

    changePhonesPopup(val) {
      this.isModalPhonesPopup = val
    },

    changeChangeAddressPopup(val) {
      if(this.isModalSelfLabelContactPopup) {
        this.isModalSelfLabelContactPopup = false
      } else {
        this.isModalChangeAddressPopup = val
      }
    },

    changeHistoryOfChanges(val) {
      this.isModalHistoryOfChanges = val
    },

    selfLabelContactPopup(val) {
      this.isModalSelfLabelContactPopup = val
    },
  }
}
</script>

<style scoped>

</style>
