import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "../../../globalModels/Files";


export function UserDocuments() {

  this.checkValid = checkValid

  this.data = {
    documentType: null,
    number: '',
    Files: new Files({
      maxCountFiles: 5,
      maxSizeFiles: 3
    }),
    mark: false,
  }

  this.validation = {
    documentType: false,
    number: false,
  }

  this.validationTranslate = {
    documentType: '',
    number: '',
  }

  this.validationTxt = {
    documentType: false,
    number: false,
  }


  /**
   * Getters
   */

  this.getDocumentType = () => {
    return this.data.documentType
  }
  this.getNumber = () => {
    return this.data.number
  }
  this.getMark = () => {
    return this.data.mark
  }


  /**
   * Setters
   */
  this.setDocumentType = (val) => {
    this.data.documentType = val
  }
  this.setNumber = (val) => {
    this.data.number = val
  }
  this.setMark = (val) => {
    this.data.mark = val
  }


}


/**
 * Global Setters
 */


UserDocuments.prototype.setItem = function (val) {
  this.setMark(val?.documents_checked === 1)
}


/**
 * Validations
 */

UserDocuments.prototype.userDocumentsValidate = function () {

  let files = this.data.Files
  if(files.data.files.length === 0){
    return true
  }

  let validationItems = {
    documentType: this.data.documentType,
    number: this.data.number,
  }

  let validationOptions = {
    documentType: {type: ['empty']},
    number: {type: ['empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}


/**
 * Prepare Data
 */

UserDocuments.prototype.prepareSave = function() {

  let formDataArray = []
  let documents = this.data
  let files = documents.Files.data.files

  files.map((item) => {
    let reader = new FormData();
    reader.append("file", item.file);
    reader.append("description", documents.number);
    reader.append("group", documents.documentType.name);
    formDataArray.push(reader)
  })

  return formDataArray
}
