<template>
  <modal
          @close="$emit('close')"
          class=" custom-popup-modal custom-popup"
  >
    <template slot="header">
      {{$t('profile_UrgentCodes.localization_value.value')}}
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="block-table">
          <div class="block-table__row" v-for="(item, index) in codes" :key="index">
            <div class="block-table__col">
              <div class="block-table__content">
                <div class="urgent-odes">
                  {{ item.code }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn">
        <span
                class="site-link site-link--alt custom-popup__btn-i"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";

  export default {
    name: "UrgentCodesPopup",

    components: {
      Modal,
    },

    props: {
      userId: {
        type: Number,
        default: null
      },
    },

    data() {
      return {
        codes: [],
      }
    },

    mounted() {

      let typeDispatch = 'twoFactorGoogleEmergency'
      let data = ''

      if(this.userId) {
        typeDispatch = 'twoFactorGoogleEmergencyByUserId'
        data = {'user_id': this.userId}
      }

      this.$store.dispatch(typeDispatch, data).then((response) => {
        this.codes = this.getRespData(response).codes
      })

    }
  }
</script>

<style lang="scss">
  .urgent-odes{
    margin-bottom: 15px;
    font-size: 16px;
  }
</style>
