<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup phones-popup"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['userModule_ChangePhone'])"></div>
        {{ $t('userModule_ChangePhone.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col flex-column z-index--10"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_Phone1', 'userModule_MarkMain'])"></div>
            <PhoneSelect
                    :label="$t('userModule_Phone1.localization_value.value')"
              :value="Phones.data.firstPhone"
              :socialTypes="socialTypes"
              :Phones="Phones"
              :selectedSocial="Phones.data.firstPhoneSocial"
              :validationField="Phones.validation.firstPhone"
              :validationTranslateField="Phones.validationTranslate.firstPhone"
              @changePhone="changeFirstPhone"
            />
            <RadioDefault
              class="mt-3"
              :label="$t('userModule_MarkMain.localization_value.value')"
              :name="'radioPhone'"
              :value="Phones.data.mainPhone === 1"
              @click.native="Phones.data.mainPhone = 1"
            />
          </div>
          <div class="custom-col flex-column z-index--9"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_Phone2', 'userModule_MarkMain'])"></div>
            <PhoneSelect
                    :label="$t('userModule_Phone2.localization_value.value')"
                :value="Phones.data.secondPhone"
                :socialTypes="socialTypes"
                :Phones="Phones"
                :selectedSocial="Phones.data.secondPhoneSocial"
                :validationField="Phones.validation.secondPhone"
                :validationTranslateField="Phones.validationTranslate.secondPhone"
                @changePhone="changeSecondPhone"
            />
            <RadioDefault
                class="mt-3"
                :label="$t('userModule_MarkMain.localization_value.value')"
                :name="'radioPhone'"
                :value="Phones.data.mainPhone === 2"
                @click.native="Phones.data.mainPhone = 2"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_save'])"></div>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
                {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i"
            :value="$t('common_save.localization_value.value')"
            @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../commonModals/Modal";
  import MainButton from "../../../UI/buttons/MainButton/MainButton";
  import PhoneSelect from "../../PhoneSelect/PhoneSelect";
  import RadioDefault from "../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import {Phones} from "./models/Phones";

  export default {
    name: "ChangePhonesPopup",
    components: {
      Modal,
      MainButton,
      PhoneSelect,
      RadioDefault,
    },

    props: {
      user: Object,
    },

    // watch: {
    //   userData(newVal){
    //     if(newVal?.id){
    //       this.getUserPhones()
    //     }
    //   }
    // },
    //
    // computed: {
    //   userData() {
    //     return this.user.user
    //   }
    // },

    data() {
      return {
        pass01: '',
        pass02: '',

        phoneTypes: [],
        socialTypes: [],

        Phones: new Phones(),
      }
    },

    mounted() {
      this.$store.dispatch('fetchPhonesData').then(response => {
        this.phoneTypes = this.getRespData(response)?.contact_phone_types
        this.socialTypes = this.getRespData(response)?.social_types
      })

      if(this.user.user?.id){
        this.user.user.user_personal_contact.contact_phones.map(item => {
          if(item?.contact_phone_type?.name === 'Primary'){
            this.Phones.setFirstPhone(item.phone_number)
            this.Phones.setFirstPhoneSocial(item.social_messengers)
          } else {
            this.Phones.setSecondPhone(item.phone_number)
            this.Phones.setSecondPhoneSocial(item.social_messengers)
          }
        })
      }
    },

    methods: {

      changeFirstPhone(val){
        this.Phones.setFirstPhone(val)
      },

      changeSecondPhone(val){
        this.Phones.setSecondPhone(val)
      },

      save() {

        if(!this.Phones.phonesValidation()){
          return
        }
        this.updateUserPhones()
      },

      updateUserPhones(){
        let data = this.Phones.prepareSave(this.user.user?.id, this.phoneTypes)


        let dispatchName = 'updateUserPhones'
        if(this.isAdmin){
          dispatchName = 'updateUserPhonesWithoutPermission'
        }

        this.$store.dispatch(dispatchName, {id: this.user.user?.id, data: data}).then(response => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
              this.$emit('close')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })
      },
    }
  }
</script>

<style lang="scss">
  .phones-popup .modal-component__inner{
    max-width: 418px;
  }

  .phones-popup .modal-component__body{
    z-index: 3;
  }
</style>
