<template>
  <Modal
          @close="$emit('close')"
          class="address-editing-modal address-editing"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_ChangePassword'])"></div>
        {{$t(`common_ChangePassword.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="address-editing__content">
        <div class="section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['login_password'])"></div>
          {{$t(`login_password.localization_value.value`)}}
        </div>

        <div class="address-editing__row custom-row">
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['login_newPassword'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.newPass}"
                    :error="validation.newPass"
                    :errorTxt="serverError ? validationTxt.newPass : $t(`${validationTranslate.newPass}.localization_value.value`)"
                    :label="$t('login_newPassword.localization_value.value')"
                    :type="'password'"
                    v-model="newPass"
            />

            <div class="mt-3 wfc d-flex"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
              'common_passwordHelp',
              'common_passwordHelpDetail'
              ])"></div>
              <span class="password-help">
                {{ $t('common_passwordHelp.localization_value.value') }}
                <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                >
                  <TooltipBtn
                          class="tooltip-target"
                  />
                  <template slot="popover">
                    <div class="password-help__tooltip">
                      <PasswordRequirements
                          :value="newPass"
                          :visibleValidation="visibleValidation"
                      />
                    </div>
                  </template>
                </v-popover>
              </span>
            </div>
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['login_RepeatPassword'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': validation.newPassConfirm}"
                    :error="validation.newPassConfirm"
                    :errorTxt="serverError ? validationTxt.newPassConfirm : $t(`${validationTranslate.newPassConfirm}.localization_value.value`)"
                    :label="$t('login_RepeatPassword.localization_value.value')"
                    :type="'password'"
                    v-model="newPassConfirm"
            />
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="address-editing__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_update'])"></div>
        <span class="site-link site-link--alt address-editing__btn-i"
              @click="$emit('close')">
                {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                :value="$t('common_update.localization_value.value')"
                class="address-editing__btn-i"
                v-on:click.native="submitForm"
                v-bind:class="{'disabled-btn' : $store.getters.getProfileBtn}"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
  import DefaultInput from "../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "../../../UI/buttons/MainButton/MainButton";
  import Modal from "../../../commonModals/Modal";
  import {validation} from "../../../../services/validation";
  import {checkAccess} from "../../../../mixins/userCredentionalMixins/userCredentional";
  import TooltipBtn from "../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import PasswordRequirements from "@/components/coreComponents/PasswordRequirements/PasswordRequirements";

  export default {
    name: "ChangePassword",

    components: {
      PasswordRequirements,
      TooltipBtn,
      Modal,
      DefaultInput,
      MainButton,
    },

    mixins: [checkAccess],

    props: {
      forUser: {
        type: Boolean,
        default: false
      },
      user: Object,
    },

    data() {
      return {
        newPass: '',
        newPassConfirm: '',

        serverError: false,
        visibleValidation: false,

        validation: {
          newPass: false,
          newPassConfirm: false,
        },

        validationTxt: {
          newPass: '',
          newPassConfirm: '',
        },

        validationTranslate: {
          newPass: '',
          newPassConfirm: '',
        },
      }
    },

    methods: {
      submitForm(e) {
        e.preventDefault();

        this.visibleValidation = true

        let validationItems = {
          newPass: this.newPass,
          newPassConfirm: this.newPassConfirm,
        }


        let validationOptions = {
          newPass: {
            type: [
              'password',
              'latin',
              'empty',
            ]
          },
          newPassConfirm: {
            type: [
              'password',
              'latin',
              'empty',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;
        this.validationTranslate = validate.validationError


        if (validate.isValidate) {
          this.serverError = false;

          let data = {
            password: this.newPass,
            password_confirmation: this.newPassConfirm,
          }

          let userId = this.$store.getters.getUserProfile.id

          if (this.forUser) {
            userId = this.user?.user?.id
          }

          let dispatchName = 'changeProfilePassword'
          if(this.isAdmin){
            dispatchName = 'changeProfilePasswordWithoutPermission'
          }

          this.$store.dispatch(dispatchName, {
            id: userId,
            data: data
          }).then((response) => {

            if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              this.$emit('close');
              this.openNotify('success', 'common_notificationPasswordChanged')
            } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              this.serverError = true;

              errors.password ? (this.validation.newPass = true, this.validationTxt.newPass = errors.password[0]) : false;
              errors.password_confirmation ? (this.validation.newPassConfirm = true, this.validationTxt.newPassConfirm = errors.password_confirmation[0]) : false;

            } else if (response.response.status === this.$store.getters.GET_ERRORS.FORBIDDEN_CODE) {
              this.checkAccess2faAt()
            }

          })

        }

      },
    }
  }
</script>

<style scoped>

</style>
