<template>
  <div class="phone-select">
    <div class="phone-select__input">
      <DefaultInput
              v-bind:class="{'ui-no-valid': validationField}"
              :error="validationField"
              :errorTxt="$t(`${validationTranslateField}.localization_value.value`)"
              :label="label"
              :value="value"
              @input="changePhone"
              :type="'text'"
      />
    </div>

    <div class="phone-select__select"
         :id="`phone-select__select-` + id"
    >
      <div class="phone-select__select-head"
           @click="show = !show"
      >
        <div class="phone-select__select-ico">
          <TelegramBlackIcon
                  v-if="selectedSocial.length > 0"
          />
        </div>
        <div class="phone-select__select-count">
          +
          <template v-if="selectedSocial.length > 0">
            {{selectedSocial.length}}
          </template>
        </div>

        <div class="phone-select__select-arrow"
             :class="{'show' : show}"
        ></div>

        <div class="phone-select__select-dropdown"
             v-show="show"
             :id="`phone-select__select-dropdown-` + id"
        >
          <div class="phone-select__select-list">
            <div class="phone-select__select-item"
                 v-for="(item, index) in socialTypes"
                 :key="index"
            >
              <DefaultCheckbox
                      class="default-checkbox"
                      :label="item.name"
                      :value="_.find(selectedSocial, {id: item.id}) > -1"
                      @input="changeSocial(item)"
              />
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import DefaultInput from "../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultCheckbox from "../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TelegramBlackIcon from "../../../../public/img/social-group/telegram-black-icon.svg?inline"

  export default {
    name: "PhoneSelect",
    components: {
      DefaultInput,
      DefaultCheckbox,
      TelegramBlackIcon,
    },

    props: {
      value: [String, Number],
      label: String,
      socialTypes: Array,
      Phones: Object,
      selectedSocial: Array,
      validationField: Boolean,
      validationTranslateField: String,
    },

    data() {
      return {
        // phone: '+38 (095) 158 56 39',
        //
        // options: ['Telegram', 'Viber', 'WhatsUp'],
        //
        // list:[
        //   {
        //     label: 'Telegram',
        //     checked: true,
        //   },
        //   {
        //     label: 'Viber',
        //     checked: true,
        //   },
        //   {
        //     label: 'WhatsUp',
        //     checked: false,
        //   },
        // ],
        //
        // count: 0,
        show: false,
        id: null,
      }
    },

    mounted() {
      this.id = this._uid


      // let newCount = 0
      // this.list.map((item) => {
      //   item.checked === true ? ++newCount : false
      // })
      // this.count = newCount

      document.addEventListener('click', (e) => {
        if (e.target.closest('#phone-select__select-' + this.id) === null) {
          this.show = false;
        }
      })
    },

    methods: {
      changeSocial(item) {
        this.Phones.changeSocial(this.selectedSocial, item)
      },

      changePhone(val) {
        this.$emit('changePhone', val)
      },

      changeShow(val) {
        this.show = val
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";


  .phone-select {
    position: relative;

    .default-checkbox {
      width: auto;
    }

    &__input {
      position: relative;
      z-index: 1;
    }

    &__select {
      position: absolute;
      right: 0;
      top: 0;
      max-width: 85px;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    &__select-head {
      display: flex;
      height: 100%;
      align-items: center;
      padding-left: 10px;
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        background: $borderBrown;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 16px;
        width: 1px;
        position: absolute;
      }
    }

    &__select-ico {

    }

    &__select-count {
      margin-left: auto;
      font-size: 14px;
      line-height: 16px;
      color: black;
    }

    &__select-arrow {
      margin-left: auto;
      width: 26px;
      height: 26px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 13px;
      background-image: url("../../../assets/img/common/arr-brown.svg");


      &.show {
        transform: rotate(-180deg);
      }
    }

    &__select-dropdown {
      position: absolute;
      right: 0;
      top: calc(100% - 2px);
      min-width: 132px;
      width: 100%;
      z-index: 3;
    }

    &__select-list {
      display: flex;
      flex-direction: column;
      border: 1px solid #F8F4EE;
      box-shadow: 0px 4px 7px #ECE5DC;
      background: white;
    }

    &__select-item {

      .default-checkbox__label {
        padding: 15px 5px 15px 27px;

        &:before, &:after {
          left: 6px;
          top: 15px;
        }

      }

      &:hover {
        background: $borderBrownOpacity02;
      }
    }


  }

</style>
