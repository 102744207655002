<template>
  <div class="blocked-block">
    <div class="blocked-block__img">
      <BlockerIcon/>
    </div>
    <div class="blocked-block__inner color-red">
      {{value}}
    </div>
  </div>
</template>

<script>
  import BlockerIcon from '../../../../public/img/common/blocked-icon.svg?inline'

  export default {
    name: "BlockedBlock",
    components:{
      BlockerIcon,
    },

    props: [
      'value',
    ]
  }
</script>

<style lang="scss">
  @import "../../../scss/mixins/mixins";

  .blocked-block{
    display: flex;
    min-width: 80px;
    height: 80px;
    position: relative;
    justify-content: center;
    align-items: center;

    @include for-992{
      min-width: 50px;
      height: 50px;
      display: flex;
    }

    &__img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;

      svg{
        height: 100%;
        width: 100%;
      }
    }

    &__inner{
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      background: white;
      padding: 2px 2px 1px 2px;
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include for-992{
        font-size: 11px;
        line-height: normal;
        padding: 1px;
      }
    }

  }

</style>