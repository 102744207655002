<template>
  <div class="fragment">

    <AccordBlock>
      <template slot="header">
        <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['userModule_Documents'])"></span>
          {{ $t('userModule_Documents.localization_value.value') }}
        </div>
      </template>

      <template slot="body">


        <div class="section-label mb-low"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['userModule_Details'])"></div>
          {{ $t('userModule_Details.localization_value.value') }}
        </div>



        <div class="custom-row">

          <!--<div class="custom-col custom-col&#45;&#45;wfc"-->
               <!--v-for="(item, index) in userDocuments.data.Files.data.downloadFiles"-->
               <!--:key="index">-->
            <!--<ImgDocumentBlock-->
                    <!--v-if="options.length > 0"-->
                    <!--:caption="'Original file name'"-->
                    <!--:name="_.find(options, {name: item.group}).value"-->
                    <!--@showImgDoc="showSingle(item)"-->
                    <!--@removeDocument="removeDocument(item.id, index)"-->
            <!--&gt;-->
              <!--&lt;!&ndash;:name="item.data.description"&ndash;&gt;-->
              <!--<template slot="img">-->
                <!--<img v-if="item.typeBase64 === 'image'"-->
                     <!--:src="item.base64" alt="img">-->
                <!--<img v-else-->
                     <!--style="width: 57px; height: 70px;"-->
                     <!--src="/img/common/pdf_file_icon.svg" alt="ico">-->
              <!--</template>-->
            <!--</ImgDocumentBlock>-->
          <!--</div>-->

          <div class="custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
               v-for="(item, index) in userDocuments.data.Files.data.downloadFiles"
               :key="index">
            <div class="site-document" v-if="optionsDocs.length > 0">
              <span class="site-document__remove" @click="removeDocument(item.id, index)">
                <CloseIcon/>
              </span>
              <div class="site-document__img">

                <img v-if="item.typeBase64 === 'image'"
                     :src="item.base64" alt="img"
                     @click="showSingle(item)"
                >
                <img v-else
                     style="width: 30px; height: 40px;"
                     src="/img/common/pdf_file_icon.svg" alt="ico"
                     @click="showSingle(item)">
              </div>
              <div class="site-document__info">
                <div class="site-document__info-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate(['profile_type', 'profile_driveLicense'])"></span>
                  {{$t('profile_type.localization_value.value')}}
                  <span>{{_.find(optionsDocs, {name: item.group}).value}}</span>
                </div>
                <div class="site-document__info-row"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate(['profile_number'])"></span>
                  {{$t('profile_number.localization_value.value')}}
                  <span>{{item.data.description}}</span>
                </div>
              </div>
            </div>
          </div>


        </div>

        <template v-if="!showType">
          <div class="custom-row">
            <template v-if="userDocuments.data.Files.data.maxCountFiles > userDocuments.data.Files.data.downloadFiles.length">
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['profile_DocumentType'])"></div>
                <DefaultSelect
                    :options="optionsDocs"
                    :label="$t('profile_DocumentType.localization_value.value')"
                    v-bind:class="{'ui-no-valid': userDocuments.validation.documentType}"
                    :error="userDocuments.validation.documentType"
                    :errorTxt="$t(`${userDocuments.validationTranslate.documentType}.localization_value.value`)"
                    :optionsLabel="'value'"
                    :selected="userDocuments.data.documentType"
                    @change="changeType"
                />
              </div>
              <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['profile_number'])"></div>
                <DefaultInput
                    :label="$t('profile_number.localization_value.value')"
                    v-bind:class="{'ui-no-valid': userDocuments.validation.number}"
                    :error="userDocuments.validation.number"
                    :errorTxt="$t(`${userDocuments.validationTranslate.number}.localization_value.value`)"
                    :type="'text'"
                    v-model="userDocuments.data.number"
                />
              </div>
            </template>

            <div v-if="isAdminType" class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate(['profile_assignedUsers'])"></span>

              <MultiSearchSelect
                  :options="options"
                  :label="$t('profile_assignedUsers.localization_value.value')"
                  :functionSearch="functionSearch"
                  @change="changeAssignedUsers"
                  :selected="UEAdmin.data.assignedUsers"
                  :userEmail="true"
                  :typeSelect="'users'"
              />
            </div>
          </div>



          <div class="custom-row">
            <div class="custom-col flex-column">
              <DropZone class="drop-zone-bg"
                        v-if="userDocuments.data.Files.data.maxCountFiles > userDocuments.data.Files.data.downloadFiles.length"
                        :parentFiles="userDocuments.data.Files.data.files"
                        :multiple="true"
                        :maxCount="userDocuments.data.Files.data.maxCountFiles - userDocuments.data.Files.data.downloadFiles.length"
                        :maxSize="userDocuments.data.Files.data.maxSizeFiles"
                        @changeImg="changeImg"
              />
              <div class="d-flex">

                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['userModule_MarkAsChecked'])"></div>
                  <DefaultCheckbox
                      :label="$t('userModule_MarkAsChecked.localization_value.value')"
                      v-model="userDocuments.data.mark"
                  />
                </div>

                <div v-if="isAdminType"
                     class="mb-15 ml-3"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['userModule_BlockUser','userModule_BlockUserTooltipText'])"></div>
                  <SwitchCheckbox
                      :label="$t('userModule_BlockUser.localization_value.value')"
                      v-model="userAccess.blockUser"
                  >
                    <template slot="tooltip">
                      <v-popover
                          class="site-tooltip"
                          :disabled="!tooltipAddProductActive"
                          offset="5"
                          placement="top-left"
                          trigger="hover"

                      >

                        <TooltipBtn/>

                        <template slot="popover">
                          <p>
                            <b>{{$t('userModule_BlockUser.localization_value.value')}}</b>
                          </p>

                          <p>
                            {{$t('userModule_BlockUserTooltipText.localization_value.value')}}
                          </p>
                        </template>
                      </v-popover>
                    </template>
                  </SwitchCheckbox>
                </div>


              </div>
            </div>
          </div>
        </template>



      </template>
    </AccordBlock>

    <Lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    ></Lightbox>
  </div>
</template>

<script>
  import AccordBlock from "../../../../../coreComponents/AccordBlock/AccordBlock";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DropZone from "../../../../../coreComponents/DropZone/DropZone";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import ImgDocumentBlock from "../../../../../../coreComponents/ImgDocumentBlock/ImgDocumentBlock";
  import Lightbox from 'vue-easy-lightbox'
  import CloseIcon from '../../../../../../../public/img/modal-group/close-icon.svg?inline'
  import SwitchCheckbox from "@/components/UI/checkboxes/SwitchCheckbox/SwitchCheckbox";
  import TooltipBtn from "@/components/UI/tooltips/TooltipBtn/TooltipBtn";
  import MultiSearchSelect from "../../../../../UI/selectiones/MultiSearchSelect/MultiSearchSelect";
  import {filterFunc} from "../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "Documents",
    components: {
      MultiSearchSelect,
      SwitchCheckbox,
      AccordBlock,
      DefaultInput,
      DropZone,
      DefaultCheckbox,
      DefaultSelect,
      // ImgDocumentBlock,
      Lightbox,
      CloseIcon,
      TooltipBtn,
    },

    mixins: [filterFunc, usersFunc],

    props: {
      UEAdmin: Object,
      user: Object,
      userObject: Object,
      userDocuments: Object,
      userAccess: Object,
      userAccessModel: Object,
      isAdminType: Boolean,
      showType: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      userObject() {
        this.getDocumentFiles()
      }
    },

    data() {
      return {
        options: [],
        optionsDocs: [],
        imgs: [
          '/img/trash-group/passport.jpg',
          '/img/trash-group/ind-code.jpg',
        ], // Img Url , string or Array of string
        visible: false,
        index: 0, // default: 0

        tooltipAddProductActive: true,
      }
    },

    mounted() {
      this.$store.dispatch('fetchUserDocumentTypesWithoutPermissions').then((response) => {
        this.optionsDocs = this.getRespData(response)?.fileGroups
      })

      if (this.user.getUser()?.id) {
        this.getDocumentFiles()
      }
    },

    methods: {

      getDocumentFromServer(item, type = null) {
        return this.$store.dispatch('getFileFromServer', item.id).then((response) => {
          let localItem = item

          switch (type) {
            case 'image':
              localItem.typeBase64 = `image`
              localItem.base64 = `data:application/${item.extension};base64,` + response[item.id]
              break

            default:
              break
          }

          return localItem
        })
      },

      async downloadFileFromArray(array) {
        let downloadedFilesArray = []
        for (const item of array) {
          if (item?.type === 'small_image') {
            await this.getDocumentFromServer(item, 'image').then(document => {
              downloadedFilesArray.push(document)
            })
          }

          if (item?.extension === 'pdf') {
            await this.getDocumentFromServer(item, 'pdf').then(document => {
              downloadedFilesArray.push(document)
            })
          }
        }
        return downloadedFilesArray
      },

      getDocumentFiles() {
        if(this.userDocuments.data.Files.getDownloadFiles().length > 0){
          return
        }

        this.$store.dispatch('fetchUserProfileFilesWithoutPermissions', this.user.getUser()?.id).then(response => {
          let files = [
            ...this.getRespData(response)?.passport_document_files,
            ...this.getRespData(response)?.driver_document_files
          ]

          this.downloadFileFromArray(files).then((documents) => {
            let filesObj = this.userDocuments.data.Files
            filesObj.setDownloadFiles(documents)
          })
        })
      },

      openPdf(uuid) {
        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.getPdfFile + '/' + uuid
        })

        window.open(routeData.href, '_blank');
      },

      showSingle(item) {

        let isPdf = item?.extension === 'pdf'
        let id = parseInt(item.id) - 1

        if(isPdf){
          this.openPdf(item.uuid)
          return
        }

        this.$store.dispatch('getFileFromServer', id).then((response) => {

          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:application/${item?.extension};base64,` + response[id]
          })
          this.show()
        })
      },

      show() {
        this.visible = true
      },

      handleHide() {
        this.visible = false
      },

      changeType(val) {
        this.userDocuments.setDocumentType(val)
      },

      changeAssignedUsers(val) {
        this.UEAdmin.setAssignedUsers(val)
      },

      changeImg(files, maxSizeError) {
        let filesObj = this.userDocuments.data.Files

        filesObj.setFiles(files)
        filesObj.setFilesMaxSizeError(maxSizeError)
      },

      removeDocument(id, index) {
        let text = {
          title: 'common_deleteConfirm',
          txt: 'common_deleteItemAsk',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }


        let confirm = () => {


          this.$store.dispatch('removeUserDocument', id).then((response) => {
            switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                let filesObj = this.userDocuments.data.Files
                filesObj.removeDownloadFile(index)
                this.openNotify('success', 'common_notificationRecordDeleted')
                break
              }
              /**
               * Validation Error
               */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
              /**
               * Undefined Error
               */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

    }
  }
</script>

<style scoped>

</style>
