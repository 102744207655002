<template>
  <modal
          @close="$emit('close')"
          class="address-editing-modal address-editing"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['profile_personalInformationEditing'])"></div>
        {{$t(`profile_personalInformationEditing.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="address-editing__content">
        <div class="section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['registration_personalInformation'])"></div>
          {{ $t('registration_personalInformation.localization_value.value') }}
        </div>

        <div class="address-editing__row custom-row">
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_name'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': LocalUser.validation.userFirstName}"
                    :error="LocalUser.validation.userFirstName"
                    :errorTxt="$t(`${LocalUser.validationTranslate.userFirstName}.localization_value.value`)"
                    :label="$t('common_name.localization_value.value')"
                    :type="'text'"
                    v-model="LocalUser.userFirstName"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_surName'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': LocalUser.validation.userLastName}"
                    :error="LocalUser.validation.userLastName"
                    :errorTxt="$t(`${LocalUser.validationTranslate.userLastName}.localization_value.value`)"
                    :label="$t('common_surName.localization_value.value')"
                    :type="'text'"
                    v-model="LocalUser.userLastName"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_fullName'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': LocalUser.validation.userCyrillicName}"
                    :error="LocalUser.validation.userCyrillicName"
                    :errorTxt="$t(`${LocalUser.validationTranslate.userCyrillicName}.localization_value.value`)"
                    :label="$t('profile_fullName.localization_value.value')"
                    :type="'text'"
                    v-model="LocalUser.userCyrillicName"
            />
          </div>
          <!--<div class="address-editing__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;xs-100">-->
            <!--<DefaultInput-->
                    <!--v-bind:class="{'ui-no-valid': LocalUser.validation.userPhone}"-->
                    <!--:error="LocalUser.validation.userPhone"-->
                    <!--:errorTxt="$t(`${LocalUser.validationTranslate.userPhone}.localization_value.value`)"-->
                    <!--:label="$t('common_phone.localization_value.value')"-->
                    <!--:type="'text'"-->
                    <!--v-model="LocalUser.userPhone"-->
            <!--/>-->
          <!--</div>-->
          <!--<div class="address-editing__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;xs-100">-->
          <!--<DefaultInput-->
          <!--v-bind:class="{'ui-no-valid': validation.email}"-->
          <!--:error="validation.email"-->
          <!--:errorTxt="serverError ? validationTxt.email : $t(`${validationTranslate.email}.localization_value.value`)"-->
          <!--:label="'Email'"-->
          <!--:type="'text'"-->
          <!--v-model="data.user_personal_contact.email"-->
          <!--/>-->
          <!--</div>-->
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_companyName'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': LocalUser.validation.userCompany}"
                    :error="LocalUser.validation.userCompany"
                    :errorTxt="$t(`${LocalUser.validationTranslate.userCompany}.localization_value.value`)"
                    :label="$t('profile_companyName.localization_value.value')"
                    :type="'text'"
                    v-model="LocalUser.userCompany"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_aditionalEmail'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': LocalUser.validation.userAdditionalEmail}"
                    :error="LocalUser.validation.userAdditionalEmail"
                    :errorTxt="$t(`${LocalUser.validationTranslate.userAdditionalEmail}.localization_value.value`)"
                    :label="$t('profile_aditionalEmail.localization_value.value')"
                    :type="'text'"
                    v-model="LocalUser.userAdditionalEmail"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_vat'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': LocalUser.validation.userVAT}"
                    :error="LocalUser.validation.userVAT"
                    :errorTxt="$t(`${LocalUser.validationTranslate.userVAT}.localization_value.value`)"
                    :label="$t('profile_vat.localization_value.value')"
                    :type="'text'"
                    v-model="LocalUser.userVAT"
            />
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['profile_birthday'])"></div>
            <DatePickerDefault
                    :label="$t('profile_birthday.localization_value.value')"
                    v-model="LocalUser.birthday"
            >
              <template slot="body">
                <date-picker
                        v-model="LocalUser.birthday"
                        ref="date"
                        valueType="format"
                        :popup-class="'birthday-picker'"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                        :format="'YYYY-MM-DD'"
                        :placeholder="'YYYY-MM-DD'"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
          <div class="address-editing__col custom-col custom-col--50 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_Email'])"></div>
            <DefaultInput
                v-bind:class="{'ui-no-valid': LocalUser.validation.userEmail}"
                :error="LocalUser.validation.userEmail"
                :errorTxt="$t(`${LocalUser.validationTranslate.userEmail}.localization_value.value`)"
                :label="$t('userModule_Email.localization_value.value')"
                :type="'text'"
                v-model="LocalUser.userEmail"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="address-editing__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel','common_update'])"></div>
        <span
                class="site-link site-link--alt address-editing__btn-i"
                @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
                :value="$t('common_update.localization_value.value')"
                class="address-editing__btn-i"
                v-on:click.native="submitForm"
                v-bind:class="{'disabled-btn' : $store.getters.getProfileBtn}"
        />
      </div>
    </template>
  </modal>
</template>


<script>
  import Modal from "../../../commonModals/Modal";
  import DefaultInput from "../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "../../../UI/buttons/MainButton/MainButton";
  import {checkAccess} from "../../../../mixins/userCredentionalMixins/userCredentional";
  import {User} from "../../../globalModels/User";
  import DatePickerDefault from "../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'

  export default {
    name: "PersonalEditing",
    components: {
      Modal,
      DefaultInput,
      MainButton,
      DatePickerDefault,
      DatePicker,
    },

    mixins: [checkAccess],

    props: {
      user: Object,
    },

    data() {
      return {
        data: {},

        LocalUser: new User(),

        options: [{}],
      }
    },

    mounted() {
      this.LocalUser.setUser(this.user)
    },

    methods: {

      submitForm() {

        if(!this.LocalUser.userValidation({
          userEmail: true,
          userFirstName: true,
          userLastName: true,
          userCompany: true,
        })) {
          return
        }

        let data = this.LocalUser.prepareSavePersonalInfo()
        data.contacts.email = data.email


        let dispatchName = 'changeProfilePersonal'
        if(this.isAdmin){
          dispatchName = 'changeProfilePersonalWithoutPermission'
        }

        this.$store.dispatch(dispatchName, {id: this.LocalUser.getUserId(), data: data}).then((response) => {

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
              this.$emit('close')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * FORBIDDEN CODE
             */
            case this.$store.getters.GET_ERRORS.FORBIDDEN_CODE: {
              this.checkAccess2faAt()
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        });
      },
    }
  }


</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .address-editing {

    .modal-component__inner {
      max-width: 740px;
    }

    .modal-component__content {
      background: white;
    }


    &__content {

    }

    &__txt {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $borderBrown;
      margin-bottom: 30px;
    }

    &__row {
      margin-bottom: -15px;
    }

    &__col {

    }

    &__alert {
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }

    &__btn {
      display: flex;
      align-items: center;
    }

    &__btn-i {
      margin-right: 30px;

      @include for-768 {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 0;

        @include for-768 {
          margin-left: 0
        }
      }
    }
  }
</style>
