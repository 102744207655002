<template>
  <AccordBlock>
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['userModule_AccessFunctionality',])"></div>
        {{ $t('userModule_AccessFunctionality.localization_value.value') }}
      </div>
    </template>

    <template slot="body">
      <div class="section-label mb-low"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['userModule_BalanceMoney',])"></div>
        {{ $t('userModule_BalanceMoney.localization_value.value') }}
      </div>

      <div class="custom-row">

        <div class="custom-col custom-col--66 custom-col--md-100 flex-column">
          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_ATM',])"></div>
            <DefaultCheckbox
                :label="$t('userModule_ATM.localization_value.value')"
                v-model="userAccess.ATM"
            />
          </div>

          <div class="custom-row pl-4">
            <div class="custom-col custom-col--25 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_MaxWithdrawTime',])"></div>
              <InputSum
                  :label="$t('userModule_MaxWithdrawTime.localization_value.value')"
                  :disabled="!userAccess.ATM"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-bind:class="{'ui-no-valid': userAccessModel.validation.maxWithdrawAll}"
                  :error="userAccessModel.validation.maxWithdrawAll"
                  :errorTxt="$t(`${userAccessModel.validationTranslate.maxWithdrawAll}.localization_value.value`)"
                  v-model="userAccess.maxWithdrawAll"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_MaxWithdrawWeek',])"></div>
              <InputSum
                  :label="$t('userModule_MaxWithdrawWeek.localization_value.value')"
                  :disabled="!userAccess.ATM"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-bind:class="{'ui-no-valid': userAccessModel.validation.maxWithdrawWeek}"
                  :error="userAccessModel.validation.maxWithdrawWeek"
                  :errorTxt="$t(`${userAccessModel.validationTranslate.maxWithdrawWeek}.localization_value.value`)"
                  v-model="userAccess.maxWithdrawWeek"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_MaxWithdrawDay',])"></div>
              <InputSum
                  :label="$t('userModule_MaxWithdrawDay.localization_value.value')"
                  :disabled="!userAccess.ATM"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  :max="3000"
                  v-bind:class="{'ui-no-valid': userAccessModel.validation.maxWithdrawDay}"
                  :error="userAccessModel.validation.maxWithdrawDay"
                  :errorTxt="$t(`${userAccessModel.validationTranslate.maxWithdrawDay}.localization_value.value`)"
                  v-model="userAccess.maxWithdrawDay"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_MaxWithdrawPercent',])"></div>
              <InputSum
                  :label="$t('userModule_MaxWithdrawPercent.localization_value.value')"
                  :disabled="!userAccess.ATM"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-bind:class="{'ui-no-valid': userAccessModel.validation.maxPercentWithdraw}"
                  :error="userAccessModel.validation.maxPercentWithdraw"
                  :errorTxt="$t(`${userAccessModel.validationTranslate.maxPercentWithdraw}.localization_value.value`)"
                  v-model="userAccess.maxPercentWithdraw"
              />
            </div>
            <!--<div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50"-->
            <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
            <!--<div class="admin-edit" @click="editTranslate(['userModule_MaxWithoutTracking',])"></div>-->
            <!--<InputSum-->
            <!--:label="$t('userModule_MaxWithoutTracking.localization_value.value')"-->
            <!--:disabled="!userAccess.ATM"-->
            <!--:placeholder="'0'"-->
            <!--:icoType="'dollar'"-->
            <!--v-model="userAccess.maxWithoutTracking"-->
            <!--/>-->
            <!--</div>-->

          </div>
        </div>
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
          <div class="section-label mb-low"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_Status', 'userModule_NewUser'])"></div>
            {{ $t('userModule_Status.localization_value.value') }}
          </div>

          <DefaultCheckbox
              :label="$t('userModule_NewUser.localization_value.value')"
              v-model="userAccess.statusNewUser"
          />

        </div>
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
          <div class="section-label mb-low"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_Fullfilment'])"></div>
            {{ $t('userModule_Fullfilment.localization_value.value') }}
          </div>

          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_amazon'])"></div>
            <SwitchCheckbox
                :label="$t('userModule_amazon.localization_value.value')"
                v-model="userAccess.fulfilmentAmazon"
                :horizontal="true"
            />
          </div>
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_fulfillmentMerchant'])"></div>
            <SwitchCheckbox
                :label="$t('userModule_fulfillmentMerchant.localization_value.value')"
                v-model="userAccess.fulfilmentMerchant"
                :horizontal="true"
            />
          </div>
        </div>
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
          <div class="section-label mb-low"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_trackNumbers'])"></div>
            {{ $t('userModule_trackNumbers.localization_value.value') }}
          </div>

          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_sendToEtsy'])"></div>
            <SwitchCheckbox
                :label="$t('userModule_sendToEtsy.localization_value.value')"
                v-model="userAccess.trackingNumEtsy"
                :horizontal="true"
            />
          </div>
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_sendToAmazon'])"></div>
            <SwitchCheckbox
                :label="$t('userModule_sendToAmazon.localization_value.value')"
                v-model="userAccess.trackingNumAmazon"
                :horizontal="true"
            />
          </div>
        </div>
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
          <div class="section-label mb-low"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_platformsPermissions'])"></div>
            {{ $t('userModule_platformsPermissions.localization_value.value') }}
          </div>

          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_etsyApiToEmail'])"></div>
            <SwitchCheckbox
                :label="$t('userModule_etsyApiToEmail.localization_value.value')"
                v-model="userAccess.etsyAPIToEmail"
                :horizontal="true"
            />
          </div>
        </div>
        <div class="custom-col mb-0">
          <div class="section-label mb-low">
            Others
          </div>
        </div>
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['userModule_paypalPay',])"></div>
          <div class="mb-15">
            <SwitchCheckbox
                :label="$t('userModule_paypalPay.localization_value.value')"
                v-model="userAccess.paypalPay"
                :horizontal="true"
            />
          </div>

          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_useKITGroup',])"></div>
            <!--            Use KIT Group-->
            <SwitchCheckbox
                :label="$t('userModule_useKITGroup.localization_value.value')"
                v-model="userAccess.useKITGroup"
                :horizontal="true"
            />
          </div>
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_showPayPalButton',])"></div>
            <!--            Use KIT Group-->
            <SwitchCheckbox
                :label="$t('userModule_showPayPalButton.localization_value.value')"
                v-model="userAccess.showPayPalButton"
                :horizontal="true"
            />
          </div>
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_dontBlockForNB',])"></div>
            <SwitchCheckbox
                :label="$t('userModule_dontBlockForNB.localization_value.value')"
                v-model="userAccess.allowAccessWithNegativeBalance"
                :horizontal="true"
            />
          </div>
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_userUseScales',])"></div>
            <SwitchCheckbox
                :label="$t('userModule_userUseScales.localization_value.value')"
                v-model="userAccess.userUseScales"
                :horizontal="true"
            />
          </div>
        </div>
        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">
            <div class="mb-15"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_consolidationPrepayment',])"></div>
              <SwitchCheckbox
                  :label="$t('userModule_consolidationPrepayment.localization_value.value')"
                  v-model="userAccess.consolidationPrepayment"
                  :horizontal="true"
              />
            </div>
            <div class="mb-15"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_GoogleAuthentication',])"></div>
              <SwitchCheckbox
                  :label="$t('userModule_GoogleAuthentication.localization_value.value')"
                  v-model="userAccess.googleAuthentication"
                  :horizontal="true"
              />
            </div>
            <div class="mb-15"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_paymentListings',])"></div>
              <SwitchCheckbox
                  :label="$t('userModule_paymentListings.localization_value.value')"
                  v-model="userAccess.paymentListings"
                  :horizontal="true"
              />
            </div>
            <div class="mb-15"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_orderExpertUserInterface',])"></div>
              <SwitchCheckbox
                  :label="$t('userModule_orderExpertUserInterface.localization_value.value')"
                  v-model="userAccess.orderExpertUserInterface"
                  :horizontal="true"
              />
            </div>
            <div class="mb-15"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_orderICreateLabelInMinus',])"></div>
              <SwitchCheckbox
                  :label="$t('userModule_orderICreateLabelInMinus.localization_value.value')"
                  v-model="userAccess.ICreateLabelInMinus"
                  :horizontal="true"
              />
            </div>
          </div>




        <div class="custom-col custom-col--33 custom-col--md-100 flex-column">

          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_withdrawFountWithoutTracking',])"></div>
            <!--                :label="'Withdraw funds without tracking'"-->
            <SwitchCheckbox
                :label="$t('userModule_withdrawFountWithoutTracking.localization_value.value')"
                v-model="userAccess.withdrawFundsWithoutTracking"
                :horizontal="true"
            />
          </div>
          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_MaxWithoutTrackingDayLimit',])"></div>
            <DefaultInput
                :label="$t('userModule_MaxWithoutTrackingDayLimit.localization_value.value')"
                :disabled="!userAccess.withdrawFundsWithoutTracking"
                v-model="userAccess.maxWithoutTrackingDayLimit"
            />
          </div>

          <div class="mb-15" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_BlockUser','userModule_BlockUserTooltipText'])"></div>
            <SwitchCheckbox
                :label="$t('userModule_BlockUser.localization_value.value')"
                v-model="userAccess.blockUser"
            >
              <template slot="tooltip">
                <v-popover
                    class="site-tooltip"
                    :disabled="!tooltipAddProductActive"
                    offset="5"
                    placement="top-left"
                    trigger="hover"

                >

                  <TooltipBtn/>

                  <template slot="popover">
                    <p>
                      <b>{{$t('userModule_BlockUser.localization_value.value')}}</b>
                    </p>

                    <p>
                      {{$t('userModule_BlockUserTooltipText.localization_value.value')}}
                    </p>
                  </template>
                </v-popover>
              </template>
            </SwitchCheckbox>
          </div>

          <div class="mb-15"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_changeAddressLabelsYourself',])"></div>
            <SwitchCheckbox
                :label="$t('userModule_changeAddressLabelsYourself.localization_value.value')"
                v-model="userAccess.changeAddressLabelsYourself"
                :horizontal="true"
            />
          </div>

        </div>
      </div>
    </template>
  </AccordBlock>
</template>

<script>
import AccordBlock from "../../../../../coreComponents/AccordBlock/AccordBlock";
import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
import SwitchCheckbox from "../../../../../UI/checkboxes/SwitchCheckbox/SwitchCheckbox";
import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "AccessFunctionality",

  components: {
    DefaultInput,
    AccordBlock,
    DefaultCheckbox,
    InputSum,
    SwitchCheckbox,
    TooltipBtn,
  },

  props: {
    userAccess: Object,
    userAccessModel: Object,
  },

  data() {
    return {
      atm: false,
      sum01: 0,
      sum02: 0,
      sum03: 0,
      sum04: 0,
      radio: '',
      radio1: '',
      radio2: '',
      radio3: '',
      radio4: '',
      radio5: '',
      radio6: '',
      radio7: '',
      radio8: '',
      radio9: '',
      radio10: '',

      tooltipAddProductActive: true,
    }
  },

  methods: {

  }
}
</script>

<style scoped>

</style>
