<template>
<!--  <div class="default-select"-->
<!--       :class="{'default-select&#45;&#45;active' : selected && selected.length > 0}"-->
<!--  >  -->
    <div class="multi-select"
       :class="{
          'multi-select--active' : vSelectModel || globalSearch.length > 0,
          'disabled' : disabled === true
       }"
  >

    <v-select
        :options="options"
        :disabled="disabled"
        @input="onChange"
        v-model="vSelectModel"
        :filterable="false"
        append-to-body
        :calculate-position="withPopper"
        @search="functionSearch"
        :selectable="option => !option.hasOwnProperty('group')"
        multiple
    >
      <template #header>
        <div class="v-select__label">{{ label }}</div>
      </template>

      <template slot="option" slot-scope="option">
        <div class="d-flex flex-column" v-if="typeSelect === 'usersCreations'">
          {{ option.contacts[0].user_full_name }}
        </div>
        <div class="d-flex flex-column" v-if="typeSelect === 'users'">
          {{ option.contacts[0].user_full_name }}
          <template v-if="userEmail">
            <b>{{ option.contacts[0].email }}</b>
          </template>
        </div>

        <div class="d-flex flex-column" v-if="typeSelect === 'proformSearch'"
             v-bind:class="{group: _.has(option, 'group')}">
          <template v-if="_.has(option, 'group')">
            {{option.name}}
          </template>
          <template v-else>
            {{ option.value.translationStorage.ua.name }}
          </template>
        </div>

        <div class="d-flex flex-column" v-if="typeSelect === 'packaging'">
          <template>
            {{option.package_name}}
          </template>
        </div>


        <!-- *********************** ACCOUNTS *********************** -->
        <template v-if="typeSelect === 'accounts'">
          <div class="fsz11">
            <template v-if="isIndividualBank(option)">
              {{option.bank.currentTranslate.name}}<template v-if="option.card_number">
              , {{option.card_number}}</template><template v-if="option.email">
              , {{option.email}}</template><template v-if="option.phone">
              , {{option.phone}}</template>
            </template>

            <template v-if="isBusinessBank(option)">
              {{option['full_name']}},
              {{option['iban']}}
            </template>
          </div>
        </template>

        <!-- *********************** PAYPAL TRANSACTION *********************** -->
        <div class="d-flex flex-column" v-if="typeSelect === 'transactions'">
          <template>
            {{option.transaction_id}}
          </template>
        </div>

        <!-- *********************** Fbm Track Number Data *********************** -->
        <div class="d-flex flex-column" v-if="typeSelect === 'fbmTrackNumberData'">
          <template>
            {{option.tracking_number}}<template v-if="option.tracking_number">: </template>#{{ option.id }}, {{option.recipient_contact_name}}, ({{option.delivery_method.name}}), {{option.user.contacts[0].user_full_name}}
          </template>
        </div>


        <div class="d-flex flex-column" v-if="!typeSelect">
          {{option[optionsLabel]}}
        </div>

      </template>


      <template #selected-option="option">
        <template v-if="typeSelect === 'usersCreations'">
          {{option.contacts[0].user_full_name}}
        </template>
        <template v-if="typeSelect === 'users'">
          <template v-if="_.has(option, 'contacts')">
            {{option.contacts[0].user_full_name}}
          </template>
          <template v-else-if="_.has(option, 'user_personal_contact')">
            {{option.user_personal_contact.user_full_name}}
          </template>
          <template v-else>
            {{option.label}}
          </template>
        </template>
        <template v-if="typeSelect === 'proformSearch'">
          {{ option.value.translationStorage.ua.name }}
        </template>
        <template v-if="typeSelect === 'packaging'">
          {{ option.package_name }}
        </template>


        <!-- *********************** ACCOUNTS *********************** -->
        <template v-if="typeSelect === 'accounts'">


          <template v-if="_.has(option, 'bank')">
            <div class="fsz11">
              <template v-if="isIndividualBank(option)">
                {{option.bank.currentTranslate.name}}<template v-if="option.card_number">
                , {{option.card_number}}</template><template v-if="option.email">
                , {{option.email}}</template><template v-if="option.phone">
                , {{option.phone}}</template>
              </template>

              <template v-if="isBusinessBank(option)">
                {{option['full_name']}},
                {{option['iban']}}
              </template>
            </div>
          </template>
          <template v-else>
            {{option.label}}
          </template>


        </template>

        <!-- *********************** PAYPAL TRANSACTION *********************** -->
        <template v-if="typeSelect === 'transactions'">
          {{option.transaction_id}}
        </template>

        <!-- *********************** Fbm Track Number Data *********************** -->
        <template v-if="typeSelect === 'fbmTrackNumberData'">
          {{option.tracking_number}}<template v-if="option.tracking_number">: </template>#{{option.id}}, {{option.recipient_contact_name}}, ({{option.delivery_method.name}}), {{option.user.contacts[0].user_full_name}}
        </template>

        <template v-if="!typeSelect">
          {{option[optionsLabel]}}
        </template>
      </template>

      <template #search="{ attributes, events }">
        <input
            class="vs__search"
            v-bind="attributes"
            v-on="events"
            @input="changeSearch"
            @blur="globalSearch = ''"
        >
      </template>

      <template slot="no-options" @click="$refs.select.open = false">
        <template v-if="globalSearch.length > 0">
          {{$t('common_noSearch.localization_value.value')}}
        </template>
        <template v-if="globalSearch.length === 0">
          {{$t('common_enter.localization_value.value')}}
        </template>
      </template>

      <template #spinner="{ loading }">
        <div v-if="!selected && loading" style="border-left-color: rgb(192 179 163)" class="vs__spinner"></div>
      </template>

    </v-select>

    <span class="multi-select__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
    <span class="multi-select__caption" v-if="caption">{{caption}}</span>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'

  export default {
    name: "MultiSearchSelect",
    components: {
      vSelect
    },

    props: {
      options: Array,
      selected: [Object, String, Boolean, Array],
      error: [Boolean],
      errorTxt: [String],
      label: [String],
      caption: [String],
      optionsLabel: [String],
      maxLimit: {
        type: [Number, String, Boolean],
        default: false,
      },
      defaultNoOptions: {
        type: [String, Boolean],
        default: false,
      },
      disabled: {
        type: [Boolean],
        default: false,
      },
      otherValue: String,
      functionSearch: Function,
      typeSelect: String,
      userEmail: {
        type: Boolean,
        default: false,
      }
    },

    data(){
      return{
        globalSearch: '',
        vSelectModel: '',
        placement: 'bottom',
      }
    },

    mounted() {
      this.vSelectModel = this.selected;
    },

    watch: {
      selected: function(newVal) {
        this.vSelectModel = newVal;
      }
    },

    methods: {


      changeSearch(e) {
        this.globalSearch = e.target.value
      },

      onChange(){
        let value = this.vSelectModel !== null ? this.vSelectModel : ''
        this.$emit('change', value);
      },

      withPopper (dropdownList, component, {width}) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width;

        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset', options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({state}) {
                component.$el.classList.toggle('drop-up', state.placement === 'top')
              },
            }]
        });

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy();
      },

    }
  }
</script>

<style lang="scss" >
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";
  @import "vue-select/src/scss/vue-select.scss";

  .no-transaction-option{
    font-size: 12px;
    position: relative;
    top: -5px;
    color: white;

    &.black-color{
      color: $black;
    }
  }


  .multi-select{
    position: relative;


    &--active {
      .v-select__label {
        top: -5px;
        background: white;
        background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
        padding: 0 2px;
        font-size: 10px;
        line-height: 12px;
      }

      .v-select .vs__dropdown-toggle{
        border-color: #CD9E64;
      }
    }


    &.ui-no-valid .v-select .vs__dropdown-toggle{
      border: 2px solid $orange;
    }


    &__error{
      position: absolute;
      bottom: -18px;
      right: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }

    &__caption{
      width: 100%;
      text-align: right;
      font-size: 11px;
      line-height: 13px;
      color: $borderBrown;
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      margin-bottom: -10px;
    }


    &.white{


      .v-select:not(.vs--single) .vs__selected-options .vs__selected{
        background: white;
        border: 0;
        color: $black;
      }

      .v-select:not(.vs--single) .vs__selected-options .vs__selected .vs__deselect svg path{
        fill: $brown;
      }

      .v-select:not(.vs--single) .vs__selected-options .vs__selected .vs__deselect{
        top: 8px;
      }
    }


    .vs__dropdown-toggle{
      padding-bottom: 9px;
    }
  }



    .vs__no-options{
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include for-550 {
        font-size: 13px;
      }
    }

    .vs__selected{
      padding: 0;
      margin: 0;
      top: 8px;
    }

    .vs__dropdown-toggle{
      padding-left: 16px;
      min-height: 42px;
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .vs--open .vs__dropdown-toggle{
      border-color: #CD9E64;
    }

    .vs__search, .vs__search:focus{
      padding: 0;
    }



    .v-select__label{
      position: absolute;
      left: 16px;
      top: 13px;
      font-size: 14px;
      line-height: 16px;
      color: #BBAD9C;
      transition: 0.15s all;
    }

    .vs--open .v-select__label{
      top: -5px;
      background: white;
      background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
      padding: 0 2px;
      font-size: 10px;
      line-height: 12px;
    }

    .vs__actions svg path{
      fill: #8F7A61;
    }

    .vs__dropdown-menu{
      border-radius: 5px 5px 0 0;
      border: 1px solid $mainBg;
      box-shadow: 0px 4px 7px $mainBg;
      padding-top: 0;
      padding-bottom: 0;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border: 1px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        margin-right: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $borderBrown;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $borderBrown;
        opacity: .5;
      }
    }

    .vs__dropdown-option{
      min-height: 42px;
      display: flex;
      align-items: center;
      color: $black;
      white-space: normal;
      border-bottom: 1px solid $mainBg;

      &:last-child{
        border-bottom: 0;
      }

      &--highlight{
        background: $borderBrownOpacity02;
        color: $black;
      }
    }


  .v-select:not(.vs--single){

    .vs__selected-options{
      padding: 0;
      top: 2px;
      display: flex;
      flex-wrap: wrap;

      .vs__selected{
        color: white;
        background: $brown;
        margin-bottom: 3px;
        margin-right: 5px;
        font-size: 14px;
        line-height: 16px;
        padding: 4px;
        padding-left: 20px;
        position: relative;

        &:last-child{
          margin-bottom: 10px;
        }

        .vs__deselect{
          position: absolute;
          left: 1px;
          top: 7px;

          svg path{
            fill: white;
          }
        }
      }

      .vs__search{
        display: flex;
        width: 100%;
      }

    }
  }


</style>
