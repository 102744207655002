import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {UserAccess} from "./UserAccess";
import {UserPresets} from "./UserPresets";
import {UserDelivery} from "./UserDelivery";
import {UserDocuments} from "./UserDocuments";
import {User} from "../../../globalModels/User";


export function UserEdit() {

  this.checkValid = checkValid



  this.data = {
    user: new User(),

    userSelfLabelContact: new User(),

    historyOfChanged: null,

    userAccess: new UserAccess(),
    userPresets: new UserPresets(),
    userDelivery: new UserDelivery(),
    userDocuments: new UserDocuments(),
    assignedUsers: [],

  }

  this.validation = {
    userName: false,
    userPhone: false,
    timePickup: false,
  }

  this.validationTranslate = {
    userName: '',
    userPhone: '',
    timePickup: '',
  }

  this.validationTxt = {
    userName: false,
    userPhone: false,
    timePickup: false,
  }

}

/*****************   SETTERS   ******************/
// UserEdit.prototype.setCourierItem = function (val) {
//
// }

UserEdit.prototype.setHistoryOfChanged = function (val) {
  this.data.historyOfChanged = val
}

UserEdit.prototype.setAssignedUsers = function (val) {
  this.data.assignedUsers = val
}

/*****************   GETTERS   ******************/
UserEdit.prototype.getAssignedUsers = function() {
  return this.data.assignedUsers
}
// UserEdit.prototype.getCourierItem = function () {
//   return {
//
//   }
// }


/*****************   VALIDATION   ******************/

UserEdit.prototype.callingCourierValidate = function () {

  let validationItems = {
    userName: this.callingCourier.userName,
    userPhone: this.callingCourier.userPhone,
    timePickup: this.callingCourier.timePickup,
    arrivalDate: this.callingCourier.arrivalDate,
    comment: this.callingCourier.comment,
  }

  let validationOptions = {
    userName: {type: ['latin', 'empty']},
    userPhone: {type: ['phone', 'empty']},
    timePickup: {type: ['empty']},
    arrivalDate: {type: ['empty']},
    comment: {type: ['empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}
