import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function UserPresets() {

  this.checkValid = checkValid

  this.data = {

    FBMFeesCheck: true,
    FBMFees: {},
    FBMFeesByFormula: false,

    FBAFeesCheck: true,
    FBAFees: {},

    shipStationCheck: true,
    shipStationFees: null,
    shipStationType: 'easypost',
    useZoneSkipping: false,

    FBAAccountType: '',

    warehouseCommission: null,

    orderFeeForManyProducts: null,

    oneHourEngravingCost: null,

    subUsers: [],
  }

  this.validation = {
    FBMFeesCheck: false,
    FBMFees: false,
    FBMFeesByFormula: false,
    shipStationCheck: false,
    shipStationFees: false,
    FBAAccountType: false,
    subUsers: false,
  }

  this.validationTranslate = {
    FBMFeesCheck: '',
    FBMFees: '',
    FBMFeesByFormula: '',
    shipStationCheck: '',
    shipStationFees: '',
    FBAAccountType: '',
    subUsers: '',
  }

  this.validationTxt = {
    FBMFeesCheck: false,
    FBMFees: false,
    FBMFeesByFormula: false,
    shipStationCheck: false,
    shipStationFees: false,
    FBAAccountType: false,
    subUsers: false,
  }

}


/*****************   SETTERS   ******************/

UserPresets.prototype.setFBMFees = function (val) {
  this.data.FBMFees = val
}

UserPresets.prototype.setFBAFees = function (val) {
  this.data.FBAFees = val
}

UserPresets.prototype.setShipStationFees = function (val) {
  this.data.shipStationFees = val
}

UserPresets.prototype.setFBMFeesCheck = function (val) {
  this.data.FBMFeesCheck = val
}

UserPresets.prototype.setFBMFeesByFormula = function (val) {
  this.data.FBMFeesByFormula = val
}

UserPresets.prototype.setShipStationCheck = function (val) {
  this.data.shipStationCheck = val
}

UserPresets.prototype.setWarehouseCommission = function (val) {
  this.data.warehouseCommission = val
}

UserPresets.prototype.setOrderFeeForManyProducts = function (val) {
  this.data.orderFeeForManyProducts = val
}

UserPresets.prototype.setOneHourEngravingCost = function (val) {
  this.data.oneHourEngravingCost = val
}

UserPresets.prototype.setUseZoneSkipping = function (val) {
  this.data.useZoneSkipping = val
}

UserPresets.prototype.setUserPresetsItem = function (val, that) {

  let userSetting = val['user_setting']

  this.setFBMFeesCheck(userSetting['fbm_vip'] === '1')
  if(userSetting['order_process_fee'])
    this.setFBMFees(userSetting['order_process_fee'])

  if(userSetting['order_fba_process_fee'])
    this.setFBAFees(userSetting['order_fba_process_fee'])

  this.setFBMFeesByFormula(userSetting['autodetect_fee'] === 1)

  this.setShipStationCheck(userSetting['order_fbm_self_create_label'] === '1')
  if(userSetting['user_create_label_fee_id'])
    this.setShipStationFees(userSetting['user_create_label_fee'])

  if(userSetting['user_order_item_fee'] && userSetting.user_order_item_fee['item_fee']) {
    this.setOrderFeeForManyProducts(userSetting.user_order_item_fee)
  }

  this.setOneHourEngravingCost(userSetting.one_hour_engraving_cost )

  this.setUseZoneSkipping(userSetting.self_create_label_vipparcel_skipping )

  console.log(userSetting);
  if(userSetting.self_create_label_use_easypost){
    this.data.shipStationType = 'easypost'
  }
  if(userSetting.self_create_label_use_vipparcel){
    this.data.shipStationType = 'vipparcel'
  }

  if(val['commissionType'] === 'warehouse_commission'){
    this.setWarehouseCommission({
      label: that.$t('userModule_CommissionDefault.localization_value.value'),
      value: parseInt(val['commission'])
    })
  } else {
    this.setWarehouseCommission({
      label: val['commission'] + '%',
      value: parseInt(val['commission'])
    })
  }

}


/*****************   GETTERS   ******************/

UserPresets.prototype.getPrepareUserPresetsItem = function () {
  let data = this.data

  return {
    'fbm_vip': data.FBMFeesCheck ? '1' : '0',
    'order_fbm_self_create_label': data.shipStationCheck ? '1' : '0',
    'order_fee_id': data.FBMFees.id ? data.FBMFees.id : null,
    'autodetect_fee': data.FBMFeesByFormula,
    'order_fba_fee_id': data.FBAFees.id ? data.FBAFees.id : null,
    'user_create_label_fee_id': data.shipStationFees.id ? data.shipStationFees.id : null,
    // '': this.data.FBAAccountType,
    // '': this.data.subUsers,
  }
}

UserPresets.prototype.getShipStationType = function () {
  if(!this.data.shipStationCheck) return {
    self_create_label_use_easypost: false,
    self_create_label_use_vipparcel: false,
  }

  if(this.data.shipStationType === 'easypost') return {
    self_create_label_use_easypost: true,
    self_create_label_use_vipparcel: false,
  }
  if(this.data.shipStationType === 'vipparcel') return {
    self_create_label_use_easypost: false,
    self_create_label_use_vipparcel: true,
  }
}

UserPresets.prototype.getWarehouseCommission = function () {
  return this.data.warehouseCommission
}

UserPresets.prototype.getOrderFeeForManyProducts = function () {
  return this.data.orderFeeForManyProducts
}

UserPresets.prototype.getOneHourEngravingCost = function () {
  return this.data.oneHourEngravingCost
}

UserPresets.prototype.getUseZoneSkipping = function () {
  return this.data.useZoneSkipping
}


/*****************   VALIDATION   ******************/

UserPresets.prototype.userPresetsValidate = function () {

  let validationItems = {
    // userPhone: this.userPresets.userPhone,
    // timePickup: this.userPresets.timePickup,
    // arrivalDate: this.userPresets.arrivalDate,
    // comment: this.userPresets.comment,
  }

  let validationOptions = {
    // userName: {type: ['latin', 'empty']},
    // userPhone: {type: ['phone', 'empty']},
    // timePickup: {type: ['empty']},
    // arrivalDate: {type: ['empty']},
    // comment: {type: ['empty']},
  }

  if(this.data.shipStationCheck){
    validationItems.shipStationFees = this.data.shipStationFees
    validationOptions.shipStationFees = {type: ['empty']}
  }

  console.log(this.data.shipStationFees);


  return this.checkValid(validationItems, validationOptions)
}
