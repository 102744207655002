import { render, staticRenderFns } from "./Shippers.vue?vue&type=template&id=59a70e5b&scoped=true"
import script from "./Shippers.vue?vue&type=script&lang=js"
export * from "./Shippers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59a70e5b",
  null
  
)

export default component.exports