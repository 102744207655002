import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import _ from 'lodash'

export function Phones() {

  this.checkValid = checkValid

  this.data = {
    firstPhone: '',
    secondPhone: '',
    firstPhoneSocial: [],
    secondPhoneSocial: [],
    mainPhone: 1,
  }


  this.validation = {
    firstPhone: false,
    secondPhone: false,
  }

  this.validationTranslate = {
    firstPhone: '',
    secondPhone: '',
  }

  this.validationTxt = {
    firstPhone: false,
    secondPhone: false,
  }

  /**
   * Getters
   */
  this.getFirstPhone = () => {
    return this.data.firstPhone
  }
  this.getSecondPhone = () => {
    return this.data.secondPhone
  }
  this.getMainPhone = () => {
    return this.data.mainPhone
  }


  /**
   * Setters
   */
  this.setFirstPhone = (val) => {
    this.data.firstPhone = val
  }
  this.setSecondPhone = (val) => {
    this.data.secondPhone = val
  }
  this.setFirstPhoneSocial = (val) => {
    this.data.firstPhoneSocial = val
  }
  this.setSecondPhoneSocial = (val) => {
    this.data.secondPhoneSocial = val
  }
  this.setMainPhone = (val) => {
    this.data.mainPhone = val
  }
}

/**
 * Global Setters
 */

Phones.prototype.setItem = function(val) {
  this.setFirstPhone(val?.firstPhone ? val.firstPhone : '')
  this.setSecondPhone(val?.secondPhone ? val.secondPhone : '')
  this.setMainPhone(val?.mainPhone ? val.mainPhone : '')
}


/**
 * Functions
 */

Phones.prototype.changeSocial = function(selectedSocial, val) {
  let index = _.findIndex(selectedSocial, {id: val.id})
  if(index !== -1){
    selectedSocial.splice(index, 1)
    return
  }

  selectedSocial.push(val)
}


/**
 * Validations
 */

Phones.prototype.phonesValidation = function() {

  let validationItems = {}

  let validationOptions = {}

  if(this.data.mainPhone === 1){
    validationItems.firstPhone = this.data.firstPhone
    validationOptions.firstPhone = {type: ['empty']}
    this.validation.secondPhone = false
  } else {
    validationItems.secondPhone = this.data.secondPhone
    validationOptions.secondPhone = {type: ['empty']}
    this.validation.firstPhone = false
  }

  return (this.checkValid(validationItems, validationOptions))
}


/**
 * Prepare Data
 */

Phones.prototype.prepareSave = function(userId, phoneTypes) {

  let primaryPhones = _.find(phoneTypes, {name: "Primary"})
  let secondaryPhones = _.find(phoneTypes, {name: "Secondary"})
  let data =  {
    "user_id" : userId,
    "phones": [],
  }

  if(this.data.mainPhone === 1){
    data.phones.push(
      setDataPhone(this.getFirstPhone(), primaryPhones.id, this.data.firstPhoneSocial)
    )
    if(this.getSecondPhone().length > 0){
      data.phones.push(
        setDataPhone(this.getSecondPhone(), secondaryPhones.id, this.data.secondPhoneSocial)
      )
    }
  }

  if(this.data.mainPhone === 2){
    data.phones.push(
      setDataPhone(this.getSecondPhone(), primaryPhones.id, this.data.secondPhoneSocial)
    )
    if(this.getFirstPhone().length > 0) {
      data.phones.push(
        setDataPhone(this.getFirstPhone(), secondaryPhones.id, this.data.firstPhoneSocial)
      )
    }
  }

  return data

}

function setDataPhone(phoneNumber, phoneType, socials) {
  let phoneData = {
    "number": phoneNumber,
    "type": phoneType,
    "social_messengers": []
  }

  socials.map(item => {
    phoneData['social_messengers'].push(item.id)
  })

  return phoneData
}
